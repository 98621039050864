/* src/components/NewReservation/ReservationForm.css */

/* Reservation Form Container */

#mateza .reservation-form {
	background-color: var(--color-white, #ffffff);
	padding: 30px;
	border-radius: 8px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	width: 100%;
	max-width: 800px;
	margin-top: var(--alignment-margin);
  }
  
  /* Form Title */
  #mateza .reservation-form h2 {
	text-align: center;
	margin-bottom: 20px;
	color: var(--color-black, #000000);
  }
  
  /* Form Group */
  #mateza .form-group {
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
  }
  
  #mateza .form-group label {
	margin-bottom: 5px;
	color: var(--color-black, #000000);
  }
  
  #mateza .form-group .required {
	color: red;
	margin-left: 2px;
  }
  
  #mateza .form-group input,
  #mateza .form-group textarea {
	padding: 10px 12px;
	border: 1px solid #cccccc;
	border-radius: 4px;
	font-size: 16px;
	transition: border-color 0.3s ease;
  }
  
  #mateza .form-group input:focus,
  #mateza .form-group textarea:focus {
	border-color: var(--theme-color, #5DADE2);
	outline: none;
  }
  
  #mateza .form-group textarea {
	resize: vertical;
	min-height: 80px;
  }
  
  /* Submit Button */
  #mateza .submit-button {
	width: 100%;
	padding: 12px;
	background-color: var(--theme-button-color, #5DADE2);
	color: #ffffff;
	border: none;
	border-radius: 4px;
	font-size: 18px;
	cursor: pointer;
	transition: background-color 0.3s ease;
  }
  
  #mateza .submit-button:hover {
	background-color: var(--theme-button-color, #3c8ecf);
  }
  
  /* Notification */
  #mateza .notification {
	position: fixed;
	top: 20px;
	right: 20px;
	background-color: var(--theme-color, #5DADE2);
	color: #ffffff;
	padding: 15px 20px;
	border-radius: 4px;
	font-size: 16px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	animation: fade-in-out 3s forwards;
  }
  
  /* Subtitle */
  #mateza .subtitle {
	margin-bottom: 10px;
	margin-top: -20px;
	font-weight: 500;
	font-size: 18px;
	text-align: center;
	opacity: 0.6;
  }
  
  /* Box Sizing for All Elements in Form */
  #mateza .form-group * {
	box-sizing: border-box;
  }
  
  /* Keyframes for Notification Animation */
  @keyframes fade-in-out {
	0% {
	  opacity: 0;
	  transform: translateY(-20px);
	}
	10%,
	90% {
	  opacity: 1;
	  transform: translateY(0);
	}
	100% {
	  opacity: 0;
	  transform: translateY(-20px);
	}
  }
  