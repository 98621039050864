
@media screen and (max-width: 480px) {
	#mateza .calendar-container .day-square {
	  width: 30px;
	  height: 30px;
	  line-height: 30px;
	}
  
	#mateza .calendar-container .calendar-header span {
	  font-size: 14px;
	}
}



@media screen and (max-width: 350px) {
	#mateza .calendar-container .calendar {
		position: relative !important;
		padding: 0;
	}
}



@media screen and (max-width: 300px) {
	#mateza .calendar-container .day-square {
	  width: 25px;
	  height: 25px;
	  line-height: 25px;
	  font-size: 15px;
	}
  
	#mateza .calendar-container .calendar-header span {
	  font-size: 14px;
	}
}


@media screen and (max-width: 245px) {
	.calendar-container .day-square {
	  width: 24px;
	  height: 24px;
	  line-height: 24px;
	  font-size: 15px;
	}
}

@media screen and (max-width: 240px) {
	.calendar-container .day-square {
	  width: 22px;
	  height: 22px;
	  line-height: 20px;
	  font-size: 13px;
	}
}




@media screen and (max-width: 230px) {
	.calendar-container {
		gap: 0px;
	  }

	.calendar-container .day-square {
	  width: 20px;
	  height: 20px;
	  line-height: 5px;
	  font-size: 13px;
	}
}
