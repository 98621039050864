
body {
	background-color: rgb(247, 247, 247);
}
/* src/components/css/loading.css */

.montserrat-font {
	font-weight: 500;
  font-family: 'Montserrat', sans-serif;
	font-size: 14px;
  } 
  
  /* Optional: Define other styles or extend existing classes */
  