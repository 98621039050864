@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

/* Popup mode styles */
#mateza .new-reservation-page .popup-mode .top-image-section,
#mateza .new-reservation-page .popup-mode .form-section {
  display: none;
}

#mateza .new-reservation-page .open-modal-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--widget-background-color, #000);
  color: var(--widget-text-color, #fff);
  border: none;
  border-radius: 8px;
  padding: 10px 16px;
  font-size: 14px;
  cursor: pointer;
  z-index: 2147483647;
  display: flex;
  align-items: center;
}

#mateza .new-reservation-page .open-modal-button:hover {
  background-color: #000;
}

#mateza .new-reservation-page .open-modal-button .button-content {
  display: flex;
  align-items:center;
  padding: 0px 0px;
}

#mateza .new-reservation-page .open-modal-button .button-icon {
  height: 18px;
  width: auto;
  max-height: 18px;
  margin-right: 0px;
}

#mateza .new-reservation-page .open-modal-button .separator {
  height: 25px;
  width: 1px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: white;
}

#mateza .new-reservation-page .open-modal-button .button-text {
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

/* Modal Overlay */
#mateza .new-reservation-page .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2147483647;
  transition: opacity 0.3s ease-in-out;
}

#mateza .new-reservation-page .modal-overlay.show {
  opacity: 1;
}

#mateza .new-reservation-page .modal-overlay.hide {
  opacity: 0;
}

/* Modal */
#mateza .new-reservation-page .modal {
  position: absolute;
  top: 0%; /* Start hidden below the visible area */
  left: 50%;
  transform: translate(-50%, 0%); /* Center the modal */
  background-color: var(--color-white, #ffffff);
  border-radius: 8px;
  padding: 30px;
  width: 90%;
  max-width: 800px;
  z-index: 2147483647;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  opacity: 0;
  backdrop-filter: blur(0px);
  transition: opacity 0.3s ease-in-out, top 0.3s ease-in-out;
}

#mateza .new-reservation-page .modal.show {
  top: 5%; /* Move the modal into view from the top */
  opacity: 1;
  backdrop-filter: blur(5px);
  margin-top: 10px;
  background-color: var(--container-color);
}

#mateza .new-reservation-page .modal.hide {
  top: 200%;
  opacity: 0;
  backdrop-filter: blur(0px);
}

#mateza .new-reservation-page .modal-content {
  position: relative;
}

#mateza .new-reservation-page .close-modal-button {
  position: absolute;
  top: -15px;
  right: -15px;
  background-color: var(--button-color);
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  z-index: 2147483647 !important;
  box-sizing: border-box;
}

@media screen and (max-width: 480px) {
  #mateza .new-reservation-page .close-modal-button {
    top: 0px;
  }

  #mateza .new-reservation-page .modal {
    width: 95%;
    padding: 20px;
  }

  #mateza .new-reservation-page .open-modal-button {
    padding: 8px 12px;
    font-size: 12px;
  }

  #mateza .new-reservation-page .open-modal-button .button-icon {
    height: 16px;
    max-height: 16px;
    margin-right: 3px;
  }

  #mateza .new-reservation-page .open-modal-button .separator {
    margin: 0 3px;
    font-size: 10px;
  }

  #mateza .new-reservation-page .open-modal-button .button-text {
    font-size: 12px;
  }
}

#mateza .new-reservation-page .close-modal-button:hover {
  background-color: var(--button-color);
}

#mateza .new-reservation-page .modal .reservation-form {
  margin-top: 0;
  box-shadow: none;
  padding: 0;
}

#mateza .new-reservation-page .form-content {
  background-color: var(--color-white, #ffffff);
}
