#mateza .calendar-container {
	position: relative;
	width: 100%;
	transition: width 0.3s ease-in-out;
}

#mateza .calendar-container .calendar-display {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 10px;
	background-color: #fff;
	border: 1px solid var(--theme-color, #5DADE2);
	border-radius: 5px;
	cursor: pointer; /* clickable */
	user-select: none;
	text-align: left;
}

#mateza .calendar-container .calendar-display span:first-child {
	flex-grow: 1;
}

#mateza .calendar-container .calendar {
	position: absolute;
	z-index: 1000;
	width: 100%;
	background-color: #fff;
	border: 1px solid #ccc;
	margin-top: 5px;
	padding: 10px;
	border-radius: 10px;
	animation: fadeInCalendar 0.3s ease-in-out;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

@keyframes fadeInCalendar {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

#mateza .calendar-container .calendar-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}

#mateza .calendar-container .calendar-header button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-size: 18px;
}

#mateza .calendar-container .calendar-header span {
	font-size: 15px;
	color: gray;
	text-transform: none;
}

#mateza .calendar-container .calendar-weeks-wrapper {
	overflow: hidden;
	position: relative;
	width: 100%;
}

#mateza .calendar-container .calendar-table {
	width: 100%;
	border-collapse: collapse;
}

#mateza .calendar-container .calendar-table th,
#mateza .calendar-container .calendar-table td {
	width: 14.28%;
	text-align: center;
	padding: 5px;
}

#mateza .calendar-container .calendar-table th {
	color: #666;
	font-weight: normal;
	padding-bottom: 10px;
}

#mateza .calendar-container .calendar-table td {
	vertical-align: middle;
	border: none;
	opacity: 0;
	animation: fadeInDay 0.5s forwards;
	/* Delay based on position for a cascading fade-in effect */
	animation-delay: calc(var(--animation-order) * 50ms);
}

@keyframes fadeInDay {
	to {
		opacity: 1;
	}
}

#mateza .calendar-container .day-square {
	width: 44px;
	height: 44px;
	line-height: 40px;
	border-radius: 5px;
	display: inline-block;
	transition: all 0.3s ease;
	font-size: 17px;
}

#mateza .calendar-container .calendar-table td.available .day-square {
	background-color: #ccffcc;
	color: #006600;
	cursor: pointer; /* clickable */
}

#mateza .calendar-container .calendar-table td.available:hover .day-square {
	background-color: #b3ffb3;
	transform: scale(1.05);
}

#mateza .calendar-container .calendar-table td.available:active .day-square {
	background-color: #99ff99;
}

#mateza .calendar-container .calendar-table td.selected .day-square {
	background-color: #006600;
	color: #ccffcc;
	cursor: pointer; /* clickable */
}

#mateza .calendar-container .calendar-table td.unavailable .day-square,
#mateza .calendar-container .calendar-table td.gray-out .day-square {
	background-color: #e0e0e0;
	color: #999;
	cursor: not-allowed; /* not clickable */
	transform: none;
}

#mateza .calendar-container .calendar-table td.unavailable .day-square {
	background-color: rgba(139, 0, 0, 0.13);
	color: darkred;
}

#mateza .calendar-container .calendar-table td.empty .day-square {
	background: none;
	cursor: default; /* not clickable */
}

#mateza .calendar-container .arrow {
	margin-left: auto;
	color: gray;
	display: flex;
	align-items: center;
}

/* Content container states */
#mateza .calendar-content-container {
	display: block;
	transition: all 0.3s ease-in-out;
}

#mateza .calendar-content-container.calendar-collapsed .calendar-container {
	width: 100%;
}

#mateza .calendar-content-container.calendar-collapsed .boxes-container {
	width: 0;
	opacity: 0;
	pointer-events: none;
	transform: scaleY(0);
	transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

#mateza .calendar-content-container.calendar-expanded {
	display: flex;
	gap: 20px;
	align-items: flex-start;
}

#mateza .calendar-content-container.calendar-expanded .calendar-container {
	width: 60%;
}

#mateza .calendar-content-container.calendar-expanded .boxes-container {
	width: 40%;
	opacity: 1;
	pointer-events: all;
	transform: scaleY(1);
	transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* Suggestion boxes */
#mateza .boxes-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: flex-start;
	margin-top: 0px;
}

#mateza .suggestion-box {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 10px;
	padding: 10px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	cursor: pointer; /* clickable */
	transition: transform 0.2s ease, box-shadow 0.2s ease;
}

#mateza .suggestion-box:hover {
	transform: scale(1.02);
	box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

/* Responsive behavior */
@media (max-width: 900px) {
	#mateza .calendar-content-container {
		display: block;
	}
	#mateza .calendar-container {
		width: 100% !important;
	}
	#mateza .boxes-container {
		display: none;
	}
}


#mateza .boxes-container h3 {
	text-align: center;
}