@keyframes fade-in-out {
	0% {
	  opacity: 0;
	  transform: translateY(-20px);
	}
	10% {
	  opacity: 1;
	  transform: translateY(0);
	}
	90% {
	  opacity: 1;
	  transform: translateY(0);
	}
	100% {
	  opacity: 0;
	  transform: translateY(-20px);
	}
  }

  @keyframes animatedBackground {
	0% {
	  background-position: 0% 50%;
	}
	50% {
	  background-position: 100% 50%;
	}
	100% {
	  background-position: 0% 50%;
	}
  }
  
  .animated-background {
	background: linear-gradient(270deg, #ff7f50, #1e90ff, #32cd32, #ff4500);
	background-size: 800% 800%;
	animation: animatedBackground 10s ease infinite;
  }
  
