/* src/components/css/mededelingNotification.css */

#mateza .mededeling-notification {
	background-color: #e0f3ff; /* Light blue background */
	color: #0077b6; /* Darker blue text */
	border: 1px solid #0077b6; /* Darker blue border */
	padding: 0px 15px;
	margin-bottom: 20px;
	margin-top: 20px;
	border-radius: 5px;
	font-size: 16px;
	line-height: 1.5;
  }
  
  