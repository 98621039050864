/* src/components/NewReservation/ReservationFullScreen.css */

/* Top Image Section */
#mateza .top-image-section {
	flex: 0 0 25vh;
	width: 100%;
  }
  
  #mateza .top-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: var(--alignment-bottom, calc(100vh - 250px));
  }
  
  /* Form Section */
  #mateza .form-section {
	flex: 1;
	margin-top: 30px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	background-color: var(--background-color, #5DADE2);
	box-sizing: border-box;
	top: var(--alignment-top, 250px);
	left: 0;
	right: 0;
	position: absolute;
  }
  