/* src/components/NewReservation/css/successMessage.css */
  
#mateza .new-reservation-page .success-message {
	display: flex;
	align-items: center;
	background-color: #d4edda;
	color: #155724;
	padding: 15px;
	border-radius: 4px;
	margin-bottom: 20px;
  }
  
  #mateza .new-reservation-page .success-icon {
	font-size: 40px;
	margin-right: 25px;
	max-width: 20px;
  }
  
  #mateza .new-reservation-page .reservation-summary {
	background-color: #ffffff;
	padding: 20px;
	border-radius: 4px;
	margin-bottom: 20px;
	border: 1px solid #e0e0e0;
  }
  
  #mateza .new-reservation-page .reservation-summary h3 {
	margin-bottom: 15px;
	color: var(--color-black, #000000);
  }
  
  #mateza .new-reservation-page .reservation-summary ul {
	list-style-type: none;
	padding: 0;
  }
  
  #mateza .new-reservation-page .reservation-summary li {
	margin-bottom: 10px;
	font-size: 16px;
	color: var(--color-black, #000000);
  }
  
  #mateza .new-reservation-page .reservation-summary strong {
	font-weight: 600;
  }
  
  /* New styling for the email success message */
  #mateza .new-reservation-page .email-message {
	display: flex;
	align-items: center;
	background-color: #cce5ff; /* Bright blue background */
	color: #004085; /* White-blue text */
	padding: 15px;
	border-radius: 4px;
	margin-bottom: 20px;
}

#mateza .new-reservation-page .email-message .success-icon {
	font-size: 40px;
	margin-right: 25px;
	max-width: 20px;
}

@keyframes fadeInFromTop {
	from {
	  opacity: 0;
	  transform: translateY(-20px);
	}
	to {
	  opacity: 1;
	  transform: translateY(0);
	}
  }
  
  #mateza .success-page .success-message,
  #mateza .success-page .email-message {
	opacity: 0;
	animation: fadeInFromTop 0.6s ease-out forwards;
  }
  
  #mateza .success-page .success-message {
	animation-delay: 0.3s; /* Start fading in first */
  }
  
  #mateza .success-page .email-message {
	animation-delay: 0.9s; /* Start fading in after the first one */
  }
  