/* src/components/NewReservation/css/timeSelector.css */

.time-selector-container {
	position: relative;
  }
  
  .time-display {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 10px;
	background-color: #fff;
	border: 1px solid var(--theme-color, #5DADE2);
	cursor: pointer;
	user-select: none;
	text-align: left;
	border-radius: 5px;
  }
  
  .time-display span:first-child {
	flex-grow: 1;
  }
  
  .time-selector {
	position: absolute;
	z-index: 1000;
	width: 100%;
	max-height: 300px;
	overflow-y: auto;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid #ccc;
	margin-top: 5px;
	padding: 10px;
	border-radius: 10px;
	animation: fadeInTimeSelector 0.3s ease-in-out;
	top: 75px;
  }
  
  @keyframes fadeInTimeSelector {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  
  .time-period {
	margin-bottom: 15px;
  }
  
  .time-period-label {
	font-weight: bold;
	margin-bottom: 5px;
  }
  
  .time-options {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
  }
  
  .time-option {
	padding: 8px 12px;
	background-color: #ccffcc; /* Green background for available times */
	color: #006600;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.2s ease;
  }
  
  .time-option:hover {
	background-color: #b3ffb3;
  }
  
  .time-option.selected {
	background-color: #006600;
	color: #ccffcc;
  }
  
  .info-text {
	color: #666;
	font-style: italic;
  }
  
  .arrow {
	margin-left: auto;
	color: gray;
	display: flex;
	align-items: center;
  }
  
  @media screen and (max-width: 480px) {
	.time-option {
	  padding: 6px 10px;
	  font-size: 14px;
	}
  }
  