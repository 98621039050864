
#mateza .new-reservation-page {
	display: flex;
	flex-direction: column;
	min-height: 115vh; /* Delete during npx webpack */
  }

  #mateza .new-reservation-page.blur-background .top-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: var(--alignment-bottom, calc(100vh - 250px));
	filter: blur(20px); /* Adjust the blur intensity as needed */
	-webkit-filter: blur(20px); /* For better browser support */
	transform: scale(1.1);
	transition: filter 0.3s ease, -webkit-filter 0.3s ease, transform 0.3s ease;
  }