@media (max-width: 900px) {
  
	#mateza .new-reservation-page .reservation-form {
	  padding: 20px;
	}
  
	#mateza .new-reservation-page .reservation-form h2 {
	  font-size: 20px;
	}
  
	#mateza .new-reservation-page .submit-button {
	  font-size: 16px;
	  padding: 10px;
	}
  
	#mateza .new-reservation-page .top-image-section {
	  flex: 0 0 20vh;
	}


  }


  @media (max-width: 300px) {
  
	#mateza .new-reservation-page .reservation-form {
		padding: 3px;
	}
  }

 
