/* successPage.css */

/* Style for invalid input fields */
#mateza .is-invalid {
	border-color: orange;
  }

  #mateza .form-menu {
    padding: 10px 12px;
    border: var(--background-color) 1px solid;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  /* Style for error messages */
  #mateza .error-message {
	color: orange;
	font-size: 0.875em;
	margin-top: 0.25em;
	display: block;
  }
  

  #mateza .bubble-style {
    font-size: 0.8rem;
    color: var(--bubble-view-color);
    text-align: center;
    border: var(--bubble-view-color) 1px solid;
    border-radius: 200px;
    margin-bottom: 10px;
    padding: 6px 12px; /* Optional: more padding for better display */
    background-color: var(--background-color-transparant);
	cursor: pointer; /* Shows pointer cursor on hover */
}

#mateza .flex-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
	align-items: center;
	width: 100%;
	justify-content: center;
}
