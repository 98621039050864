#mateza .guest-number-selector-container {
	position: relative;
	margin-bottom: 20px;
  }
  
  #mateza .guest-number-display {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 10px;
	background-color: #fff;
	border: 1px solid var(--theme-color, #5DADE2);
	cursor: pointer;
	user-select: none;
	text-align: left;
	border-radius: 5px;
  }
  
  #mateza .guest-number-display span:first-child {
	flex-grow: 1;
  }
  
  #mateza .arrow {
	margin-left: auto;
	color: gray;
	display: flex;
	align-items: center;
  }
  
  #mateza .guest-number-selector {
	position: absolute;
	z-index: 1000;
	width: 100%;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid #ccc;
	margin-top: 5px;
	padding: 10px;
	border-radius: 10px;
	animation: fadeInGuestSelector 0.3s ease-in-out;
	top: 75px;
  }
  
  @keyframes fadeInGuestSelector {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  
  #mateza .guest-number-options {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
  }
  
  #mateza .guest-number-option {
	width: 15.5%;
	height: 45px;
	background-color: #fff;
	border: 1px solid var(--theme-color, #5DADE2);
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.2s ease;
	font-size: 16px;
	color: #333;
	text-align: center;
	line-height: 45px;
  }
  
  #mateza .guest-number-option.selected,
  #mateza .guest-number-option:hover {
	background-color: var(--theme-button-color, #5DADE2);
	border: 1px solid var(--theme-button-color, #5DADE2);
	color: #fff;
  }
  
  #mateza .guest-number-custom-input {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
  }
  
  #mateza .guest-number-custom-input input {
	width: 100%;
	padding: 10px;
	border: 1px solid var(--theme-color, #5DADE2);
	border-radius: 5px;
	font-size: 16px;
  }
  
  #mateza .guest-number-custom-input input:focus {
	border-color: var(--theme-color, #5DADE2);
	outline: none;
  }
  
  #mateza .close-custom-guest-button {
	width: 100%;
	padding: 10px;
	background-color: var(--button-color, #5DADE2);
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
	transition: background-color 0.2s ease;
  }
  
