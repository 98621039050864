@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Montserrat:wght@100;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');


#mateza .default-text-color {
	color: var(--text-color, black) !important;
}

#mateza .reservation-form {
	background-color: var(--container-color, white) !important;
}

#mateza .submit-button,
#mateza .action-button {
	background-color: var(--button-color, black) !important;
}

#mateza .guest-number-display,
#mateza .calendar-display,
#mateza .time-display {
	border-color: var(--background-color, black) !important;
}

#mateza .guest-number-option {
	border-color: var(--background-color, black) !important;
}

#mateza .guest-number-option.selected,
#mateza .guest-number-option:hover {
	background-color: var(--background-color, black) !important;
}

#mateza input,
#mateza textarea {
	border-color: var(--background-color, black) !important;
}

