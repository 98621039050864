.success-page .action-buttons {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
  }
  
  .success-page .action-button {
	flex: 1;
	margin: 0 5px;
	padding: 12px;
	background-color: var(--theme-button-color, #5DADE2);
	color: #ffffff;
	border: none;
	border-radius: 4px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	display: flex;
	align-items: center;
	justify-content: center;
  }

  .success-page .button-icon {
	margin-right: 8px;
	color: white;
	font-size: 16px;
  }
  
  .success-page .action-button:hover {
	background-color: var(--theme-button-color, #3c8ecf);
  }
  
  .success-page .action-button svg {
	margin-right: 8px;
  }