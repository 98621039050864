#mateza .general-notification {
	background-color: #e0f3ff; /* Light blue background */
	color: #0077b6; /* Darker blue text */
	border: 1px solid #0077b6; /* Darker blue border */


	background-color: #e0fffd; /* Light blue background */
	color: #1e9898; /* Darker blue text */
	border: 1px solid #1e9898; /* Darker blue border */
	padding: 15px;
	margin-bottom: 20px;
	margin-top: 20px;
	border-radius: 5px;
	font-size: 16px;
	line-height: 1.5;
  }
  
  #mateza .title-bold {
	font-weight: bold;
  }